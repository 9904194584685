<template>
  <div>
      <v-text-field label="Search term" hide-details="auto" v-model="searchTerm"></v-text-field>

      <div v-if="imageResults.length > 0" id="imageScrollTarget">
        <br />
        <ImageGallery :images="this.imageResults"></ImageGallery>
        <v-btn
            color="primary"
            elevation="4"
            v-on:click="search()"
            large
        >Get new images</v-btn>
      </div>
  </div>
</template>

<script>
import ImageGallery from "./ImageGallery";
export default {
  name: "Homepage",
  components: {ImageGallery},
  data() {
    return {
      searchTerm: "",
      awaitingSearch: false,
      imageResults: []
    }
  },
  watch: {
    searchTerm: function () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.imageResults = [];
          this.search();
          this.awaitingSearch = false;
        }, 700); // 700ms delay
      }
      this.awaitingSearch = true;
    },
  },
  methods: {
    search() {
      fetch(`https://prod-e-cdn.privateger.me/api/ecdn.php?action=image&count=50&tag=${encodeURI(this.searchTerm)}`)
        .then((result) => result.json())
        .then((result) => {
          this.imageResults = result.images;
          this.$vuetify.goTo(0)
        });
    }
  }
}
</script>

<style scoped>

</style>