<template>
  <div>
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Details
        </v-card-title>

        <v-img
            :src="`https://prod-e-cdn.privateger.me/api/files/${detailURL}`"
            lazy-src="loader.gif"
            class="grey lighten-2"
            v-on:click="download(detailURL)"
        >

        </v-img>

        <v-card-text>
          Tags: {{ detailTags.join(" ") }}
          <br /><br />
          Image ID: {{ detailID }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="dialog = false"
              block
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col
          v-for="image in images"
          :key="image.id"
          class="d-flex child-flex"
          cols="4"
      >
        <v-img
            :src="`https://prod-e-cdn.privateger.me/api/files/${image.filename}`"
            lazy-src="loader.gif"
            aspect-ratio="1"
            class="grey lighten-2"
            v-on:click="openDetails(image.filename, image.tags, image.id)"
        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </div>

</template>

<script>
export default {
  name: "ImageGallery",
  props: ["images"],
  data() {
    return {
      dialog: false,
      detailURL: "",
      detailTags: [],
      detailID: ""
    }
  },
  methods: {
    openDetails(file, tags, id) {
      console.log(file)
      console.log(tags)
      this.detailURL = file;
      this.detailTags = tags;
      this.detailID = id;
      this.dialog = true;
    },
    async download(filename) {
      let anchor = document.createElement('a');
      anchor.href = await this.toDataURL(`https://prod-e-cdn.privateger.me/api/files/${filename}`);
      anchor.target = '_blank';
      anchor.download = filename;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
    toDataURL(url) {
      return fetch(url).then((response) => {
        return response.blob();
      }).then(blob => {
        return URL.createObjectURL(blob);
      });
    }
  }
}
</script>

<style scoped>

</style>