<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      id="topScrollTarget"
    >

      <router-link to="/home" style="text-decoration: none; color: white">
        <v-toolbar-title class="shrink mr-2 flex text-center">
          E-CDN
        </v-toolbar-title>
      </router-link>

      <v-spacer></v-spacer>

    </v-app-bar>

    <v-main class="ma-4">
      <router-view></router-view>
    </v-main>

    <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {

  },

  data: () => ({
    fab: false
  }),

  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    }
  }
};
</script>
